<template>
  <el-row class="u_m--l10 u_m--r10">
    <tips :rules="rules" />
  </el-row>
  <el-row type="flex" align="end" justify="center" gutter="10" class="u_m--l10 u_m--r20">
    <el-col :span="11" class="flex flex-column flex-center flex-wrap">
      <label class="el-form-item__label">{{ $t('rp_start_time') }}</label>
      <div class="u_w--100p custom-datepicker">
        <el-date-picker
          v-model="info.dateTimeRange"
          type="daterange"
          :range-separator="$t('ui_to')"
          :start-placeholder="$t('ui_start_date')"
          :end-placeholder="$t('ui_end_date')"
          format="YYYY-MM-DD"
          size="small"
          class="u_m--t5"
          :clearable="false"
          :disabled-date="disabledDate"
        >
        </el-date-picker>
      </div>
    </el-col>
    <el-col :span="5" class="flex flex-column flex-center flex-wrap">
      <label class="el-form-item__label">{{ $t('ui_text_044') }}</label>
      <div class="u_w--100p">
        <el-select
          class="c_input--large"
          :placeholder="$t('ui_all')"
          v-model="downdata.type"
          clearable
        >
          <el-option
            v-for="(item, i) in optionsid"
            :label="item.text"
            :key="'level' + i"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </el-col>
    <el-col :span="5" class="flex flex-column flex-center flex-wrap">
      <label class="el-form-item__label">{{ $t('ui_status') }}</label>
      <div class="u_w--100p">
        <el-select class="c_input--large " v-model="downdata.status" clearable>
          <el-option
            v-for="(item, i) in optionsStatus"
            :key="'status' + i"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </div>
    </el-col>
    <el-col :span="3" class="flex flex-column flex-center flex-justify-end flex-wrap">
      <div style="height: 40px"></div>
      <el-button
        type="primary"
        class="c_button--huge j_btn-bg--primary j_btn-txt--primary main-btn"
        @click="submit"
      >
        <span class="c_button__text">{{ $t('btn_search') }}</span>
      </el-button>
    </el-col>
  </el-row>
  <div class="u_m--t20">
    <custom-table
      :titleList="table.title"
      :list="showList"
      :title-class="'background-th'"
      :table="table"
      @click="handleTrClick"
      :pagination="pagination"
      @change="handleChange"
      :loading="tableLoading"
    >
      <template v-slot:default="{item: {key, item}}">
        <span v-if="key === 'remark'">
          <i class="el-icon-setting u_c--pointer" @click="openSetting(item)"></i
        ></span>
      </template>
    </custom-table>
  </div>

  <el-dialog
    v-model="showDetail"
    center
    :close-on-click-modal="false"
    width="600px"
    custom-class="c_dialog"
  >
    <template #title>
      <el-row :class="`u_p--b10 u_t--center title`" type="flex" align="middle" justify="center">
        <span>{{ detail.type_name }} {{ $t('btn_set') }}</span>
      </el-row>
    </template>
    <div class="dialog-content">
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <span>{{ $t('rp_act_start_time') }}：</span>
          <span>{{ detail.active_time }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ $t('rp_act_end_time') }}：</span>
          <span>{{ detail.active_end_time }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <span>{{ $t('active_status') }}：</span>
          <span :class="[detail.status === 1 ? 'text-primary' : 'text-tomato']">{{
            $t(`ui_agent_redenvelope_status_id[${detail.status - 1}]`)
          }}</span>

          <el-button
            type="primary"
            size="small"
            v-if="detail.status == 1"
            @click="stopAction(detail)"
            >{{ $t('ui_stop_activity') }}</el-button
          >
        </el-col>
        <el-col :span="12">
          <span>{{ $t('ui_text_026') }}：</span>
          <span>{{ detail.receive_num }} {{ ' ' + $t('ui_each') }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <span>{{ $t('ui_text_032') }}：</span>
          <span>{{ detail.threshold }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ $t('ui_text_039') }}：</span>
          <span>{{ detail.stock_num }} {{ ' ' + $t('ui_each') }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <span>{{ $t('ui_red_envelope_amount') }}：</span>
          <span>{{ detail.price }}{{ ' ' + getUnit('ui_dollar') }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ $t('ui_total_sent_amount') }}：</span>
          <span>{{ detail.expect_money }} {{ ' ' + getUnit('ui_dollar') }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="12">
          <span>{{ $t('ui_goal_text_035') }}：</span>
          <span>{{ detail.total_num }}{{ ' ' + $t('ui_each') }}</span>
        </el-col>
        <el-col :span="12">
          <span>{{ $t('ui_text_040') }}：</span>
          <span>{{ detail.pay_money }} {{ ' ' + getUnit('ui_dollar') }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" v-if="detail.not_login_day">
        <el-col :span="12">
          <span>{{ $t('ui_text_078') }}：</span>
          <span>{{ detail.not_login_day }}{{ ' ' + $t('ui_day_day') }}</span>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {ElMessage, ElMessageBox} from 'element-plus';
import Tips from '@/common/web/tips/index.vue';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';

import {App, Format} from '@/services';
import {luckmoney} from '@/services/server/web/luckmoney';

import {IProxyTableResult, IProxyTable, IStopActionResult} from '@/model/luckmoney';
import {IPage} from '@/model/commission';
@Options({
  components: {
    Tips,
    CustomTable
  }
})
export default class Proxy extends Vue {
  config = App.getConfig() || {
    currency: {
      id: '',
      code: 'CNY',
      text: ''
    },
    dollarSign: '¥'
  };
  userinfo = App.getUserinfo();
  now = new Date();
  table = {
    list: [],
    title: [
      {
        name: 'rp_start_time',
        key: 'active_time'
      },
      {
        name: 'rp_end_time',
        key: 'active_end_time'
      },
      {
        name: 'rp_act_type',
        key: 'type_name'
      },
      {
        name: 'ui_text_026',
        key: 'receive_num'
      },
      {
        name: 'rp_the_remaining_amount',
        key: 'stock_num'
      },
      {
        name: 'ui_text_027',
        key: 'status_name'
      },
      {
        name: 'rp_status',
        key: 'remark'
      }
    ],
    keys: [
      'active_time',
      'active_end_time',
      'type_name',
      'receive_num',
      'stock_num',
      'status_name',
      'remark'
    ]
  };
  options = [];
  info = {
    start_time: Format.formatYMDLine(this.now),
    end_time: Format.TimeHandle.format(new Date(), 'YYYY-MM-DD'),
    dateTimeRange: [new Date(new Date().setMonth(new Date().getMonth() - 2)), new Date()],
    vant_start_time: '',
    vant_end_time: '',
    loading: false,
    birthDate: '',
    showstart: false,
    showend: false,
    maxDate: new Date(),
    minDate: new Date('2020/01/01')
  };
  downdata = {
    type: '',
    status: ''
  };
  showList: IProxyTable[] = [];
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  rules = ['ui_text_0095'];
  termClick(name: string) {
    this.$router.push({name});
  }
  detail: IProxyTable = {
    type_name: '',
    active_day: 0,
    active_end_time: '',
    active_id: 0,
    active_time: '',
    choose_user: [],
    expect_money: 0,
    not_login_day: 0,
    pay_money: 0,
    price: 0,
    receive_num: 0,
    status: 0,
    status_name: '',
    stock_num: 0,
    threshold: 0,
    total_num: 0,
    type: 0
  };
  showDetail = false;

  unitMap = new Map([
    ['USD', 'ui_dollar_usd'],
    ['VND', 'ui_dollar_vnd'],
    ['THB', 'ui_dollar_thb'],
    ['CNY', 'ui_dollar_cny']
  ]);

  get optionsid() {
    const opts = [];
    opts.push({value: '', text: this.$t('ui_all')});
    opts.push({value: 1, text: this.$t('ui_agent_redenvelope_id[0]')});
    opts.push({value: 2, text: this.$t('ui_agent_redenvelope_id[1]')});
    opts.push({value: 3, text: this.$t('ui_agent_redenvelope_id[2]')});
    opts.push({value: 4, text: this.$t('ui_agent_redenvelope_id[3]')});
    return opts;
  }
  get optionsStatus() {
    const opts = [];
    opts.push({value: '', text: this.$t('ui_all')});
    opts.push({value: 1, text: this.$t('ui_agent_redenvelope_status_id[0]')});
    opts.push({value: 2, text: this.$t('ui_agent_redenvelope_status_id[1]')});
    opts.push({value: 3, text: this.$t('ui_agent_redenvelope_status_id[2]')});
    return opts;
  }
  get dollarUnit() {
    const code = this.config.currency ? this.config.currency.code : 'CNY';
    return this.unitMap.get(code);
  }

  mounted() {
    this.submit();
    eventBus.bus$on('langChange', () => {
      this.submit();
    });
  }
  beforeDestroy() {
    eventBus.bus$off('langChange');
  }
  disabledDate = (time: Date) => {
    // time.getTime() >= beginDateVal false 可用，true 不可用
    return (
      time.getTime() > this.info.maxDate.getTime() || time.getTime() < this.info.minDate.getTime()
    ); //今天及以后的日期都不能选
  };

  getDate() {
    const [start, end] = this.info.dateTimeRange;
    console.log('start, end: ', start, end);
    this.info.start_time = Format.formatYMDLine(start);
    this.info.end_time = Format.formatYMDLine(end);
    return [Format.formatYMDLine(start), Format.formatYMDLine(end)];
  }
  async submit() {
    // console.log(this.info.dateTimeRange, 'dateTimeRange')
    this.getDate();
    let opts: {
      start_date: string;
      end_date: string;
      type?: string;
      status?: string;
    } = {
      start_date: this.info.start_time,
      end_date: this.info.end_time
    };

    if (this.downdata.type) {
      opts = {
        ...opts,
        type: this.downdata.type
      };
    }
    if (this.downdata.status) {
      opts = {
        ...opts,
        status: this.downdata.status
      };
    }
    const data = await luckmoney.report<IProxyTableResult>(opts);
    // this.loading = false;
    if (data instanceof Error) {
      ElMessage({type: 'error', message: data.message});
      return false;
    } else {
      // this.agentlist = data;
      this.showList = data.list;
      this.pagination = data.pagination;
    }
    return data;
  }
  getUnit(key: string) {
    if (key) {
      return this.$t(key, {
        dollar: this.$t(this.dollarUnit || 'ui_dollar_cny')
      });
    } else {
      return '';
    }
  }
  openSetting(item: IProxyTable) {
    this.detail = item;
    this.showDetail = true;
  }
  stopAction(item: IProxyTable) {
    const options = {
      title: this.$t('ui_remind'),
      message: `${this.$t('ui_text_047')}${item.type_name}`,
      closeOnClickOverlay: true,
      showCancelButton: true,
      cancelButtonText: this.$t('btn_cancel'),
      confirmButtonText: this.$t('btn_confirm'),
      zIndex: 3000,
      callback: async (action: string) => {
        console.log('action: ', action);
        if (action === 'confirm') {
          const data = await luckmoney.deleted<IStopActionResult>({
            active_id: item.active_id,
            type: item.type
          });
          if (data instanceof Error) {
            ElMessage({
              type: 'error',
              message: data.message
            });
          } else {
            ElMessage({
              type: 'info',
              message: data.message
            });
            this.showDetail = false;
            this.pagination.page = 1;
            this.submit();
          }
          // this.stop_active({
          //   active_id: item.active_id,
          //   type: item.type
          // }).then(response => {
          //   console.log('response', response);
          //   this.page = 1;
          //   this.check_set_active();
          //   this.query();
          //   // successMessageBox({message: response.data.message});
          // });
        }
      }
    };
    ElMessageBox(options);
    // confirmMessageBox(options);
  }
  dateTimeSubstitution() {
    // console.log('datetime', datetime)
    if ((this.info.start_time || '').indexOf('-') > -1) {
      return this.info.start_time.replace('-', '</br>');
    } else {
      return '';
    }
  }
}
</script>

<style lang="scss" scoped>
.dateinput {
  width: 6.72rem;
  height: 1.28rem;
  background: #f6f7f8;
  border-radius: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-btn {
  width: 107px;
  background: #1f55f0;
  border-radius: 8px;
  border-color: #1f55f0;
  align-self: flex-end;
}
.u_m--l10 {
  margin-left: 10px;
}
.u_m--r20 {
  margin-right: 20px;
}
.u_m--r10 {
  margin-right: 10px;
}
.custom-datepicker {
  :deep(.el-range-editor) {
    width: 100% !important;
    height: 40px !important;
  }
}
.text-day {
  color: $cf;
}
.c_dialog .title {
  font-size: 24px;
  font-weight: bold;
}
.dialog-content {
  .el-row {
    height: 40px;
    padding: 0 10px;
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
}
.u_m--t20 {
  margin-top: 20px;
}
:deep(.el-date-editor .el-range-separator) {
  width: 72px;
  line-height: 32px;
}
</style>
